import React from 'react'
import { graphql } from 'gatsby'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Layout from '../components/Layout'
import SEO from '../components/seo'

import AboutUsGate from '../assets/images/about-us-gate.jpg'

class AboutUs extends React.Component {
	render() {
		const siteTitle = 'About | J & J Gates Service and Design'
		const siteDescription = this.props.data.site.siteMetadata.description

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />
				<div className="container-fluid text-center headline gates-headline">
					<div className="container">
						<h1 className="text-white m-0">
							About J & J Gates Service and Designs
						</h1>
						<hr />
					</div>
				</div>
				<div className="container pt-5 d-padding-b text-justify">
					<div className="row flex-column-reverse flex-md-row mb-5">
						<div className="col-md-12">
							<p>
								When you need quality gate installation in Dallas and Fort
								Worth, TX, look no further than J & J Gates Service and Design.
								With years of hands-on experience, we specialize in all areas of
								gate design, installation, and repair. Whether you need
								residential or commercial services, make J & J Gates Service and
								Design your top choice. Call us today at{' '}
								<a href="tel:8174662764">817-466-2794</a> to learn more about
								our extensive options for gate design, access control systems,
								and more.
							</p>
						</div>
					</div>
					<div className="row flex-column-reverse flex-md-row">
						<div className="col-md-6">
							<h4 className="mt-0">Meet the Owner</h4>
							<p>
								Aaron Jungbluth has over 10 years of experience working with
								automated gates. He started his company in Arlington, TX with a
								handful of employees and has now grown his business into a
								thriving industry. Aaron is committed to providing customers
								with excellent customer service and timely repairs. There are
								several Dallas-Fort Worth gate design, service, repair, and
								installation companies to choose from, but we hope you will
								select us. We are appreciative of the opportunity to earn your
								business and want to prove it! Not only do we provide free
								estimates, but we will also complete the job efficiently and
								economocally if hired.{' '}
								<a href="https://www.yelp.com/biz/j-and-j-garage-and-gates-arlington-2">
									Check out J & J Gates Service and Design on Yelp
								</a>
							</p>
						</div>
						<div className="text-center col-md-6">
							<LazyLoadImage
								className="img-fluid"
								src={AboutUsGate}
								alt="About Us Gate"
							/>
						</div>
					</div>
					<div className="row flex-column-reverse flex-md-row">
						<div className="col-md-12">
							<h4 className="mt-0">Types of Gate Services We Offer</h4>
							<p>
								We offer a wide array of services to meet the gate needs of the
								DFW community. Our services include custom design, installation,
								and repair of residential gates, openers, and access control
								systems. We know and understand the importance of being
								versatile and putting the needs of customers first. Therefore,
								no matter what contractors installed your gate automation, we
								can and will provide repairs, or will install a new one if
								necessary. If you are looking a professional, automatic gate
								services in the Arlington, TX or Dallas-Fort, TX area, give us a
								call at <a href="tel:8174662794">817-466-2794</a> today! We'd be
								happy to help you with gate installation or repairs.
							</p>
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default AboutUs

export const AboutUsPageQuery = graphql`
	query AboutUsPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
